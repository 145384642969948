import React from "react"
import { useHeaderData } from "shared/Hooks/Headers"
import Case from '../../sections/Case';
import {useCaseData} from '../../shared/Hooks/Case';

const CaseCersanit = () => {
  const data = {
    seo: {
      title: 'Cersanit and Opoczno - a shop-in-shop project.',
    },
    cases: useCaseData().cersanit,
    header: useHeaderData().cersanit,
  };

  return (
    <Case data={data}/>
  );
}

export default CaseCersanit
